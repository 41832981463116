.sublogo-container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 53px;
    background-color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 5px;
}

.auth-form > .form-input:not(:last-child) {
    margin-bottom: 40px;
}

.auth-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 80px;
    margin-left: 80px;
    cursor: pointer;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
}

.navbar-username {
    font-size: 16px;
    font-weight: 500;
}

.dropdown-menu::before {
    content: '';
    position: absolute;
    top: -7px;
    right: 28px;
    width: 12px;
    height: 12px;
    background: #fff;
    transform: rotate(45deg);
    border-left: solid 1px #e5e5e5;
    border-top: solid 1px #e5e5e5;
}

.dropdown-menu:not(.show) {
    display: unset;
    margin-top: 30px;
    visibility: hidden;
    opacity: 0;
}

.user-dropdown .show {
    display: block;
    margin-top: 17px;
    visibility: visible;
    opacity: 1;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    float: left;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 1px;
    border-color: #e5e5e5;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
    z-index: 301 !important;
    font-size: 0.85rem;
    padding: 10px 0;
    transition: 0.3s;    
    min-width: 210px;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropdown-item svg {
    width: 30px;
    margin-right: 15px;
}

.dropdown-item:active {
    background-color: #e9ecef;
    color: #2B37EE;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
    border: none;
    outline: none;
}